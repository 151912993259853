body {
	background-color: #282c34;
}

*, *::before, *::after {
	box-sizing: border-box;
}

html, body {
	height: 100vh;
	min-height: 100vh;
	max-height: 100vh;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

body, #root, .App {
	height: 100%;
}

.App {
	text-align: center;
	max-width: 728px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
}



/* ChatRoom component page */
.App.ChatRoom {
	max-height: 100%; 
	flex-grow: 1;
	overflow-y: hidden;
	background-color: rgb(40, 37, 53);

	.top-bar {
		/*background-color: #181717;*/
		height: 10%;
		min-height: 50px;
		color: white;
		width: 100%;
		max-width: 728px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		/*z-index: 99; */
		/*padding: 10px; */
		background-color: black;
	/*	div {
			margin-left: 2em;
			color: white;
			font-size: 150%;
			font-weight: bold;
		}*/

		button.flag-selector {
			min-height: 100%;
			span {
				min-width: 2.4em;
				height: 1.6em;
			}
		}

		.sign-out {
			color: #282c34;
			background: white;
			max-width: 400px;
			min-height: 100%;
			margin: 0;
			/*margin: 0 auto; */
			/*position: fixed; */
			/*bottom: 0; */
		}
	}

	main {
		padding: 10px;
		height: 80vh;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		flex: 1;
		&::-webkit-scrollbar { width: 0.25rem; }
		&::-webkit-scrollbar-track { background: #1e1e24; }
		&::-webkit-scrollbar-thumb { background: #6649b8; }
	}

	/* message input */
	form {
		height: 10vh;
		background-color: rgb(24, 23, 23);
		width: 100%;
		max-width: 728px;
		display: flex;
		font-size: 1.5rem;

		input {
			line-height: 1.5;
			width: 100%;
			font-size: 1.5rem;
			background: rgb(58, 58, 58);
			color: white;
			outline: none;
			border: none;
			padding: 0 10px;
		}

		button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 20%;
			background-color: rgb(56, 56, 143);
			padding: 16px;
		}
	}
}



button {
  background-color: #282c34; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;

	&::disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}


.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  /*margin: 0 auto; */
  margin: auto;
}

ul, li {
  text-align: left;
  list-style: none;
}


.message {
  display: flex;
 	align-items: center;

	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin: 2px 5px;
	}

	p {
		max-width: 500px;
		margin-bottom: 12px;
		line-height: 24px;
		padding: 10px 20px;
		border-radius: 25px;
		position: relative;
		color: white;
		text-align: center;
	}

	&.received p {
		display: flex;
		align-items: center;
		text-align: left;
		background: #e5e5ea;
		color: black;
		margin-right: 2em;
	}

	&.sent {
		flex-direction: row-reverse;
		p {
			color: white;
			background: #0b93f6;
			align-self: flex-end;
		}
	}
}


/* dummy AI response, three animated dots while waiting for server response */
.dummy {
	&.hidden {
		display: none;
	}

	&.waiting p {
		background: #e5e5ea;
		color: black;
		margin-right: 2em;
		max-width: 5em;
		min-width: 5em;
		border-radius: 25px;

		&::after {
			content: ' .';
			animation: dots 1s steps(5, end) infinite;
		}
	}
	@keyframes dots {
		0%, 20% {
			color: rgba(0,0,0,0);
			text-shadow:
				.25em 0 0 rgba(0,0,0,0),
				.5em 0 0 rgba(0,0,0,0);}
		40% {
			color: black;
			text-shadow:
				.25em 0 0 rgba(0,0,0,0),
				.5em 0 0 rgba(0,0,0,0);}
		60% {
			text-shadow:
				.25em 0 0 black,
				.5em 0 0 rgba(0,0,0,0);}
		80%, 100% {
			text-shadow:
				.25em 0 0 black,
				.5em 0 0 black;}
	}
}


/* Flag selector dialog */
.FlagDialog {
  animation-name: dialogClose;
  animation-duration: 0.3s;

	&[open] {
		color: black;
		padding: 0;
		width: 12em;
		border: 1px solid black;
		animation-name: dialogOpen;
		animation-duration: 0.3s;
	}

	&::backdrop {
		background: rgba(0, 0, 0, 0.5);
	}

	button {
		float: right;
	}
	div.languages {
		display: flex;
		flex-direction: column;
		min-width: 100%;
		padding: 1em 0;

		div {
			padding: 0 2em;
			display: flex;
			flex-direction: row;
			height: 3.5em;
			min-width: 100%;

			span {
				min-width: 2.4em;
				margin-right: 1em;
				//font-siz: 4em;
			}
			p {
				display: flex;
				align-items: center;
			}
		}
	}

	@keyframes dialogOpen {
		  0% { opacity: 0; transform: scale(0.7); }
		100% { opacity: 1; transform: scale(1  ); }
	}

	@keyframes dialogClose {
		  0% { opacity: 1; transform: scale(1  ); }
		100% { opacity: 0; transform: scale(0.7); }
	}
}

